<style lang="scss" scoped>
  .font-weight-bold {
    font-weight: bold;
    text-align: center;
    width: 100%; /* Ensure the text spans the full cell width */
  }

  .button-right {
    position: absolute;
    right: 1em;
  }

  @media all and (max-width: 1199px) {
    .button-right {
      position: relative;
      right: 0;
    }
  }
</style>
<style lang="scss">
  .no-details-padding {
    padding: 0 !important;
  }
</style>
<template>
  <b-container fluid>
    <div class="filter-bar p-2 mb-3">
      <b-navbar toggleable="xl">
        <b-navbar-toggle target="filter-collapse"></b-navbar-toggle>
        <b-collapse id="filter-collapse" is-nav>
          <button @click="resetFilters()" class="filter-btn reset">Reset Filters</button>
          <b-form-select
            :options="companies"
            text-field="name"
            value-field="id"
            v-model="filters.company"
            plain
            class="select company"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >Select Company</b-form-select-option>
            </template>
          </b-form-select>

          <b-form-select
            :options="teams"
            text-field="teamname"
            value-field="teamname"
            v-model="filters.team"
            plain
            class="select company"
          >
            <template #first>
              <b-form-select-option
                :value="null"
              >Select Team</b-form-select-option>
            </template>
          </b-form-select>

          <div class="d-inline-block ml-4">
            <b-form-checkbox class="d-inline" value="1" unchecked-value="0" v-model="filters.open"></b-form-checkbox> Show all open
          </div>

          <div class="d-inline-block ml-4 full-width">Due:</div>
          <b-form-select
            :options="years"
            v-model="filters.year"
            plain
            class="select"
          ></b-form-select>

          <b-form-select
            :options="periods"
            v-model="period"
            plain
            class="select"
          >
            <template #first>
              <b-form-select-option :value="null">Month</b-form-select-option>
            </template>
          </b-form-select>

          <button @click="exportData()" class="filter-btn reset button-right">Export <b-icon icon="download"></b-icon></button>
        </b-collapse>
      </b-navbar>
    </div>
    <div v-if="stats">
    <b-overlay :show="loading">
      <b-card class="card-border table-card text--black">
        <b-table
          style="min-width: 1500px;"
          :items="stats" :fields="fields"
          @row-clicked="expand"
          foot-clone no-footer-sorting
          details-td-class="no-details-padding"
          fixed hover
        >

          <template #head(open)>
            Open {{ totals !== null ? '(' + totals.open + ')' : '' }}
          </template>
          <template #head(hold)>
            Hold {{ totals !== null ? '(' + totals.hold + ')' : '' }}
          </template>
          <template #head(late)>
            Late {{ totals !== null ? '(' + totals.late + ')' : '' }}
          </template>
          <template #head(waiting)>
            Waiting {{ totals !== null ? '(' + totals.waiting + ')' : '' }}
          </template>
          <template #head(review)>
            Review {{ totals !== null ? '(' + totals.review + ')' : '' }}
          </template>
          <template #head(complete)>
            Complete {{ totals !== null ? '(' + totals.complete + ')' : '' }}
          </template>
          <template #head(cwq)>
            Closed w/<br>Questions {{ totals !== null ? '(' + totals.cwq + ')' : '' }}
          </template>
          <template #head(quaterly)>
            Quarterly {{ totals !== null ? '(' + totals.quaterly + ')' : '' }}
          </template>
          <template #head(semi)>
            Semi-Annual {{ totals !== null ? '(' + totals.semi + ')' : '' }}
          </template>
          <template #head(annual)>
            Annual {{ totals !== null ? '(' + totals.annual + ')' : '' }}
          </template>
          <template #head(total)>
            Total {{ totals !== null ? '(' + totals.total + ')' : '' }}
          </template>

          <template #cell(name)="row">
            <b-icon
              v-if="row.item.name !== 'Onboarding Tickets'"
              :icon="row.detailsShowing ? 'caret-down-fill' : 'caret-up-fill'"
              class="float-left mt-1"
            ></b-icon>
            <span v-if="row.item.name != 'Onboarding Tickets'">
              {{ row.item.name }}
            </span>
            <div v-if="row.item.name === 'Onboarding Tickets'">
              <span :colspan="fields.length" class="text-center font-weight-bold">
                {{ row.item.name }}
              </span>
            </div>
          </template>
          <template #row-details="row">
            <b-table fixed
              :items="row.item.details" :fields="detailfields" v-if="row.item.name !== 'Onboarding Tickets'"
            >
              <template #cell(fullname)="row">
                <router-link :to="'/bookkeeping?assignee=' + row.item.id" class="text--black u">{{ row.item.fullname }}</router-link>
              </template>
            </b-table>
          </template>
          <template #cell(total)="row">
            <span  v-if="row.item.name !== 'Onboarding Tickets'">{{ row.item | total }}</span>
          </template>

          <template #foot(name)>
            Totals
          </template>
          <template #foot(open)>
            {{ totals !== null ? totals.open : '' }}
          </template>
          <template #foot(hold)>
            {{ totals !== null ? totals.hold : '' }}
          </template>
          <template #foot(late)>
            {{ totals !== null ? totals.late : '' }}
          </template>
          <template #foot(waiting)>
            {{ totals !== null ? totals.waiting : '' }}
          </template>
          <template #foot(review)>
            {{ totals !== null ? totals.review : '' }}
          </template>
          <template #foot(complete)>
            {{ totals !== null ? totals.complete : '' }}
          </template>
          <template #foot(cwq)>
            {{ totals !== null ? totals.cwq : '' }}
          </template>
          <template #foot(quaterly)>
            {{ totals !== null ? totals.quaterly : '' }}
          </template>
          <template #foot(semi)>
            {{ totals !== null ? totals.semi : '' }}
          </template>
          <template #foot(annual)>
            {{ totals !== null ? totals.annual : '' }}
          </template>
          <template #foot(total)>
            {{ totals !== null ? totals.total : '' }}
          </template>
        </b-table>
      </b-card>
      </b-overlay>
    </div>
  </b-container>
</template>
<script>
import TicketService from '@/services/TicketService'
import AdminService from '@/services/AdminService'
import moment from 'moment'

export default {
  data () {
    return {
      stats: [],
      totals: null,
      fields: [
        { key: 'name', label: '', sortable: false },
        { key: 'open', sortable: true },
        { key: 'hold', sortable: true },
        { key: 'late', sortable: true },
        { key: 'waiting', sortable: true },
        { key: 'review', sortable: true },
        { key: 'complete', sortable: true },
        { key: 'cwq', label: 'Closed w/ Questions', sortable: true },
        { key: 'quaterly', label: 'Quaterly', sortable: true },
        { key: 'semi', label: 'Semi-Annual', sortable: true },
        { key: 'annual', label: 'Annual', sortable: true },
        'total'
      ],
      detailfields: [
        { key: 'fullname', label: 'Employee', sortable: true },
        { key: 'open', sortable: true },
        { key: 'hold', sortable: true },
        { key: 'late', sortable: true },
        { key: 'waiting', sortable: true },
        { key: 'review', sortable: true },
        { key: 'complete', sortable: true },
        { key: 'cwq', label: 'Closed w/ Questions', sortable: true },
        { key: 'quaterly', label: 'Quaterly', sortable: true },
        { key: 'semi', label: 'Semi-Annual', sortable: true },
        { key: 'annual', label: 'Annual', sortable: true },
        'total'
      ],
      filters: {
        team: null,
        company: null,
        year: null,
        month: null,
        quarter: null,
        open: 1
      },

      loading: false,

      period: null,
      teams: [],
      years: [],
      periods: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      companies: []
    }
  },

  mounted () {
    this.filtersinit()
    this.getOverview()
  },

  methods: {
    getOverview () {
      this.loading = true
      this.stats = []
      TicketService.breakdown(this.filters).then(
        (res) => {
          // Filter out the object with name "Finalization" and OB set to true
          this.stats = res.data.info.stats.filter(stat =>
            !(stat.name === 'Finalization' && stat.OB === true)
          )
          // Find the Finalization object where OB is true to adjust totals
          const obFinalization = res.data.info.stats.find(stat =>
            stat.name === 'Finalization' && stat.OB === true)
          // Subtract the values in obFinalization from totals if it exists
          if (obFinalization) {
            Object.keys(obFinalization).forEach(key => {
              if (key !== 'name' && key !== 'type' && key !== '_showDetails' && key !== 'OB') {
                res.data.info.totals[key] -= obFinalization[key]
              }
            })
          }
          this.totals = res.data.info.totals
        }
      ).finally(() => { this.loading = false })
    },

    async expand (row, index, event) {
      if (row.name === 'Onboarding Tickets') {
        console.log('1')
        event.preventDefault()
      } else {
        console.log('2')
        if (row._showDetails) {
          row._showDetails = false
          return
        }
        this.loading = true
        const filt = {
          type: row.type,
          OB: row.OB,
          team: this.filters.team,
          company: this.filters.company,
          year: this.filters.year,
          month: this.filters.month,
          quarter: this.filters.quarter,
          open: this.filters.open
        }
        await TicketService.detail(filt).then(
          (res) => {
            row.details = res.data.info
          }
        ).finally(() => { this.loading = false })

        row._showDetails = !row._showDetails
      }
    },

    getTeams () {
      this.teams = []
      AdminService.getTeams().then(
        (res) => {
          this.teams = res.data.info
        }
      )
    },

    filtersinit () {
      this.getTeams()
      AdminService.getCompanies().then(
        (res) => {
          this.companies = res
        }
      )
      this.filters.month = moment().format('MM')
      this.period = moment().format('MMM')
      this.filters.year = +moment().format('YYYY')

      TicketService.reportingFilters().then(
        (response) => {
          this.years = response.data.info.years
        }
      )
    },

    resetFilters () {
      this.filters = {
        team: null,
        company: null,
        year: null,
        month: null,
        quarter: null,
        open: 1
      }
      this.filtersinit()
    },

    exportData () {
      TicketService.export(this.filters).then(
        (res) => {
          const a = document.createElement('a')
          a.href = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + res.data.info
          if (this.filters.company) {
            let company = ''
            this.companies.some(e => {
              if (e.id === this.filters.company) {
                company = e.name
                return true
              } else {
                return false
              }
            })
            a.download = company + '_ticket_report_' + this.filters.year + '_' + moment().month(this.filters.month - 1).format('MMM') + '.xlsx'
          } else {
            a.download = 'ticket_report_' + this.filters.year + '_' + moment().month(this.filters.month - 1).format('MMM') + '.xlsx'
          }
          a.click()
        }
      )
    }
  },

  watch: {
    filters: {
      handler: async function (n, o) {
        if (n) {
          this.getOverview()
        }
      },
      deep: true
    },

    period (n, o) {
      if (n !== o) {
        if (n === null) {
          this.filters.month = null
          this.filters.quarter = null
        } else {
          if (n[0] === 'Q') {
            this.filters.month = null
            this.filters.quarter = n[1]
          } else {
            this.filters.quarter = null
            this.filters.month = moment().month(n).format('MM')
          }
        }
      }
    }
  },

  filters: {
    total (val) {
      if (val) {
        return val.open + val.hold + val.late + val.waiting + val.review + val.cwq + val.complete + val.annual + val.quaterly + val.semi
      } else {
        return ''
      }
    }
  }
}
</script>
